import React from "react";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import navigate from "lib/navigate";
import { autobind } from "react-decoration";
import qs from "query-string";
import ReactHtmlParser from "react-html-parser";

@withI18next(["common"])
@page
class Maintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  @autobind
  openidSSOLoginAlephSubmit(e) {
    e.preventDefault();
    let { session } = this.props.readerStore;
    let params = {
        code: session.openidCode,
        token: session.openidToken,
        setAleph: "1"
    }
    navigate(["/openidSSOLogin", qs.stringify(params)].join("?"));
  }

  render() {
    return (
      <>
        <link href="/file/css/openidSSO.css" rel="stylesheet" />
        <div className="login">
          <form id="openidSSOLoginAlpehForm" name="form1">
            {ReactHtmlParser(this.props.t("jumperrwd.openidSSO.loginAlephContent"))}            
            <input
              type="button"
              name="submitButton"
              id="submitButton"
              value="同意"
              style={{ margin: "10px" }}
              onClick={this.openidSSOLoginAlephSubmit}
            />
            <input
              type="button"
              name="rejectButton"
              id="rejectButton"
              value="不同意"
              style={{ margin: "10px" }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
            />
          </form>
        </div>
      </>
    );
  }
}

export default Maintenance;
